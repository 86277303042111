import { ICustomer } from '../../models/Customer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CustomerState {
    customer: ICustomer;
    isLoading: boolean;
    error: string;
}

const initialState: CustomerState = {
    customer: {
        customerId: '',
        dob: '',
        firstName: '',
        lastName: '',
        loanTerm: 0,
        loanAmount: 0,
        providers: [],
    },
    isLoading: false,
    error: '',
};

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        customerFetching(state) {
            state.isLoading = true;
        },
        customerFetchingSuccess(state, action: PayloadAction<ICustomer>) {
            state.isLoading = false;
            state.error = '';
            state.customer = action.payload;
        },
        customerFetchingError(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

export default customerSlice.reducer;
